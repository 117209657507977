'use client';

import ErrorPlaceholder from '@frontend/jetlend-web-ui/src/components/ErrorPlaceholder/ErrorPlaceholder';
import * as Sentry from '@sentry/nextjs';
import { useEffect } from 'react';

const CONTAINER_STYLE: React.CSSProperties = {
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
};

/**
 * Глобальный обработчик ошибок рендеринга компонентов.
 */
export default function GlobalError({
    error,
}: {
  error: Error & { digest?: string };
}) {
    // Sentry не логирует автоматически перехваченные ошибки, поэтому логируем их самостоятельно
    useEffect(() => {
        Sentry.captureException(error);
    }, [error]);

    return (
        <html lang="ru">
            <body>
                <div style={CONTAINER_STYLE}>
                    <ErrorPlaceholder startPageLocation="/" />
                </div>
            </body>
        </html>
    );
}